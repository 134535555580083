<template>
  <div class="info-box" v-on:click.prevent.stop="onClick(item)">
    <span
      class="info-box-icon"
      v-bind:class="{
        'btn-success': item.nConnectedStations && !item.nAlarmedStations,
        'btn-danger': item.nAlarmedStations
      }"
    >
      <i class="fa fa-tag"></i>
    </span>
    <div class="info-box-content">
      <span class="info-box-text">{{ item.title }}</span>
      <span
        class="info-box-number"
        v-if="item.nConnectedStations == item.nStations"
      >
        <i class="fa fa-link"></i>
        {{ item.nConnectedStations }}/{{ item.nStations }}
        {{ $tc("connected_devices") }}
      </span>
      <span class="info-box-number" v-else>
        <i class="fa fa-unlink"></i>
        {{ item.nConnectedStations }}/{{ item.nStations }}
        {{ $tc("connected_devices") }}
      </span>
      <div class="progress-group">
        <div class="progress progress-sm">
          <div
            class="progress-bar bg-green"
            v-bind:style="{
              width:
                parseInt((item.nConnectedStations / item.nStations) * 100) + '%'
            }"
          ></div>
        </div>
      </div>
      <div class="text-small">
        <span v-if="!item.nAlarmedStations" class="">
          <i class="fa fa-bell"></i> {{ $tc("alarm") }}: {{ $t("normal") }}
        </span>
        <span v-else class="text-danger">
          <i class="fa fa-bell"></i>
          {{ $tc("alarm") }}:
          {{ $t("active") }}
          {{ $t("for") }}
          {{ item.nAlarmedStations }}
          {{ $tc("device", item.nAlarmedStations) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProcessAreaSelectionCardOption",
  props: {
    item: {
      type: Object,
      required: false,
      default: () => {
        return {
          id: 1,
          title: "Untitled process area",
          nStations: 0,
          nConnectedStations: 0,
          nAlarmedStations: 0,
          ids: null
        };
      }
    }
  },
  methods: {
    onClick(item) {
      this.$emit("click", item);
    }
  }
};
</script>

<style scoped>
.info-box {
  opacity: 1;
}
.info-box:hover {
  cursor: pointer;
  opacity: 0.8;
}

.info-box-number {
  font-size: 14px;
}

@media (min-width: 720px) {
  .info-box-number {
    font-size: 18px;
  }
}
</style>
