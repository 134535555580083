var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-box",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.onClick(_vm.item)}}},[_c('span',{staticClass:"info-box-icon",class:{
      'btn-success': _vm.item.nConnectedStations && !_vm.item.nAlarmedStations,
      'btn-danger': _vm.item.nAlarmedStations
    }},[_c('i',{staticClass:"fa fa-tag"})]),_c('div',{staticClass:"info-box-content"},[_c('span',{staticClass:"info-box-text"},[_vm._v(_vm._s(_vm.item.title))]),(_vm.item.nConnectedStations == _vm.item.nStations)?_c('span',{staticClass:"info-box-number"},[_c('i',{staticClass:"fa fa-link"}),_vm._v(" "+_vm._s(_vm.item.nConnectedStations)+"/"+_vm._s(_vm.item.nStations)+" "+_vm._s(_vm.$tc("connected_devices"))+" ")]):_c('span',{staticClass:"info-box-number"},[_c('i',{staticClass:"fa fa-unlink"}),_vm._v(" "+_vm._s(_vm.item.nConnectedStations)+"/"+_vm._s(_vm.item.nStations)+" "+_vm._s(_vm.$tc("connected_devices"))+" ")]),_c('div',{staticClass:"progress-group"},[_c('div',{staticClass:"progress progress-sm"},[_c('div',{staticClass:"progress-bar bg-green",style:({
            width:
              parseInt((_vm.item.nConnectedStations / _vm.item.nStations) * 100) + '%'
          })})])]),_c('div',{staticClass:"text-small"},[(!_vm.item.nAlarmedStations)?_c('span',{},[_c('i',{staticClass:"fa fa-bell"}),_vm._v(" "+_vm._s(_vm.$tc("alarm"))+": "+_vm._s(_vm.$t("normal"))+" ")]):_c('span',{staticClass:"text-danger"},[_c('i',{staticClass:"fa fa-bell"}),_vm._v(" "+_vm._s(_vm.$tc("alarm"))+": "+_vm._s(_vm.$t("active"))+" "+_vm._s(_vm.$t("for"))+" "+_vm._s(_vm.item.nAlarmedStations)+" "+_vm._s(_vm.$tc("device", _vm.item.nAlarmedStations))+" ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }